<template>
    <!-- <div class="kkinfo"> kkChat |
        <img src="https://web-kkchat.oss-cn-hangzhou.aliyuncs.com/%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87.png" alt="备案图标" style="width: 20px;">
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21010602000843"> 辽公网安备 21010602000843号 </a>
         | 
        <a href="https://beian.miit.gov.cn"> 辽ICP备2023004621号-1 </a>
    </div> -->
    <div></div>
</template>

<script>
export default{
    name: 'WebInfo',
}
</script>

<style scoped>
.kkinfo 
{
    color: rgb(241, 241, 241);
    margin: 20px 0;
    text-align: center;
    font-size: 14px;
}

</style>