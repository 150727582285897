<template>
  <div id="LoadingBlock" style="display: none;">
    <div style="position: fixed; width: 100%; height: 100%; color: rgb(81, 81, 81); font-size: 14px;">
      <div style="position: absolute; top: 40%; left: 50%; text-align: center;">
          <div class="spinner-border text-secondary" role="status"></div>
          <div> 加载中... </div>
      </div>
    </div>
  </div>
  <NavBar></NavBar>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-3 kkcol3">
      <div style="border-bottom: 1px solid #d4d6dc;">
        <img style="width: 100px; margin-top: 20px; margin-bottom: 12px;" :src="user_info.photo" alt="">
        <div></div>
        <img src="../assets/用户.png" alt="用户" style="width: 23px; margin-right: 5px; margin-bottom: 7px;">
        <span class="kk2"> {{ user_info.uname }} </span>
        <div></div>
        <img src="../assets/荣誉称号.png" alt="称号" style="width: 15px; margin-right: 10px;">
        <span class="kkdes"> {{ user_info.description }} </span>
        <div style="margin-bottom: 20px;"></div>
      </div>
      <div>
        <div class="kktag">
          <img src="../assets/好友数.png" alt="好友数" style="width: 18px; margin-bottom: 4px;">
          好友数
          <div style="color: #3d3d3d;"> {{ user_info.friends_num }} </div>
        </div>
        <div class="kktag">
          <img src="../assets/帖子数.png" alt="帖子数" style="width: 18px; margin-bottom: 4px;">
          帖子数
          <div style="color: #3d3d3d;"> {{ user_info.posts_num }} </div>
        </div>
        <div class="kktag">
          <img src="../assets/获赞数.png" alt="获赞数" style="width: 18px; margin-bottom: 4px;">
          获赞数
          <div style="color: #3d3d3d;"> {{ user_info.getPraises }} </div>
        </div>
      </div>
    </div>
    <div class="col-7 kkcol7">
      <div>
        <div class="kkset"> 账号信息 </div>
        <div class="kkitem">
          <span style="width: 20%; font-size: 14px;"> 头像 </span>
          <img @click="dispaly_control1" src="../assets/云上传.png" alt="云上传" style="width: 20px; float: right;">
          <div style="display: none; width: 60%; margin-top: 5px;" id="modifyPhotoBlock">
            <input class="form-control kkinput" type="file" id="formFile">
            <span class="err" style="float: right;"> {{ modphoto_errmsg }} </span>
            <button @click="modify_photo" type="button" class="btn btn-primary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem; margin-right: 10px; margin-top: 5px; margin-bottom:10px;">上传</button>
            <button @click="disappear_control1" type="button" class="btn btn-secondary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;  margin-top: 5px; margin-bottom: 10px;">取消</button>
          </div>
        </div>
        <div class="kkitem">
          <span style="width: 20%; font-size: 14px;"> 昵称 </span>
          <span style="font-size: 13px; color: #9d9d9d; margin-left: 5px;"> {{ user_info.uname }} </span>
          <img @click="dispaly_control2" src="../assets/修改.png" alt="修改" style="width: 20px; float: right;">
          <div style="display: none; width: 60%; margin-top: 5px;" id="modifyUnameBlock">
            <input v-model="new_uname" type="text" class="form-control kkinput" placeholder="输入新昵称~">
            <span class="err" style="float: right;"> {{ moduname_errmsg }} </span>
            <button @click="modify_uname" type="button" class="btn btn-primary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem; margin-right: 10px; margin-top: 5px; margin-bottom:10px;">修改</button>
            <button @click="disappear_control2" type="button" class="btn btn-secondary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;  margin-top: 5px; margin-bottom: 10px;">取消</button>
          </div>
        </div>
        <div class="kkitem">
          <span style="width: 20%; font-size: 14px;"> 称号 </span>
          <span style="font-size: 13px; color: #9d9d9d; margin-left: 5px;"> {{ user_info.description }} </span>
          <img @click="dispaly_control3" src="../assets/修改.png" alt="修改" style="width: 20px; float: right;">
          <div style="display: none; width: 60%; margin-top: 5px;" id="modifyDescriptionBlock">
            <input v-model="new_description" type="text" class="form-control kkinput" placeholder="输入新称号~">
            <span class="err" style="float: right;"> {{ moddescription_errmsg }} </span>
            <button @click="modify_description" type="button" class="btn btn-primary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem; margin-right: 10px; margin-top: 5px; margin-bottom:10px;">修改</button>
            <button @click="disappear_control3" type="button" class="btn btn-secondary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;  margin-top: 5px; margin-bottom: 10px;">取消</button>
          </div>
        </div>
        <div class="kkitem">
          <span style="width: 20%; font-size: 14px;"> 密码 </span>
          <img @click="dispaly_control4" src="../assets/修改密码.png" alt="修改密码" style="width: 20px; float: right;">
          <div style="display: none; width: 60%; margin-top: 5px;" id="modifyPasswordBlock">
            <input v-model="old_password" type="password" class="form-control kkinput" placeholder="输入当前密码~" style="display: inline-block; width: 45%; margin-right: 10px;">
            <input v-model="new_password" type="password" class="form-control kkinput" placeholder="输入新密码~" style="display: inline-block; width: 45%;">
            <div></div>
            <span class="err" style="float: right; margin-right: 30px;"> {{ modpassword_errmsg }} </span>
            <button @click="modify_password" type="button" class="btn btn-primary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem; margin-right: 10px; margin-top: 5px; margin-bottom:10px;">重置</button>
            <button @click="disappear_control4" type="button" class="btn btn-secondary" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;  margin-top: 5px; margin-bottom: 10px;">取消</button>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 20px;"></div>
      <div>
        <div class="kkset"> 关联第三方账号 </div>
        <div class="kkitem">
          <img src="../assets/微信.png" alt="微信" style="width: 20px; margin-right: 5px; margin-bottom: 2px;">
          <span style="width: 20%; font-size: 14px;"> 微信 </span>
          <img src="../assets/关联.png" alt="关联" style="width: 20px; float: right;">
        </div>
      </div>
      <div style="margin-bottom: 20px;"></div>
      <div>
        <div class="kkset"> 帖子管理 
          <img src="../assets/新建帖子.png" alt="新建帖子" style="float: right; width: 23px; margin-top: -3px;" data-bs-toggle="modal" data-bs-target="#add_post">
        </div>
        <table class="table table-striped table-hover">
          <thead>
            <tr style="font-size: 14px;">
              <th>标题</th>
              <th>发布时间</th>
              <th>更新时间</th>
              <th>获赞</th>
              <th>操作 </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="post in posts" :key="post.id">
              <td>
                <div style="word-break: break-all; font-weight: bold; color: black; max-width: 160px; text-align: center;">{{ post.title }}</div>
              </td>
              <td>
                <div style="font-size: 13px; color: #5e5e5e;">{{ post.create_time }}</div>
              </td>
              <td>
                <div style="font-size: 13px; color: #5e5e5e;">{{ post.modify_time }}</div>
              </td>
              <td>
                <div style="font-size: 13px; color: #5e5e5e;">{{ post.praises_num }}</div>
              </td>
              <td>
                <img src="../assets/修改帖子.png" alt="修改帖子" style="float: left; width: 20px;" data-bs-toggle="modal" :data-bs-target="'#update_post_' + post.id">

                <!-- Modal update_post-->
                <div class="modal fade" :id="'update_post_' + post.id" tabindex="-1">
                  <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel" style="margin-left: 10px;">修改帖子~</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <div class="mb-3">
                          <label for="post_title" class="form-label">标题</label>
                          <input v-model="post.title" type="text" class="form-control" id="post_title" placeholder="在此输入标题~">
                        </div>
                        <div class="mb-3">
                          <label for="exampleFormControlTextarea1" class="form-label">正文</label>
                          <textarea v-model="post.content" class="form-control" id="exampleFormControlTextarea1" rows="7"
                            placeholder="在此输入正文~"></textarea>
                        </div>
                        <div class="kk7" @mouseover="emoji_display(post.id)" @mouseleave="emoji_disappear(post.id)">
                          <img src="../assets/emoji_light.png" style="width: 30px; height: 30px;" />
                          <EmojiPicker :native="true" @select="onSelectEmoji($event, post)" style="display: none;" :id="'EmojiPicker_' + post.id" class="kk12"/>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <div class="err">{{ modify_post_errmsg }}</div>
                        <button type="button" class="btn btn-primary btn-sm" @click="update_post(post)">修改</button>
                        <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">关闭</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Modal -->
                <img src="../assets/删除帖子.png" alt="删除帖子" style="float: right; width: 20px;" data-bs-toggle="modal" :data-bs-target="'#del_confirm_' + post.id">

                <!-- 删除帖子确认 Modal -->
                <div class="modal fade" :id="'del_confirm_' + post.id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">删除帖子~</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body" style="text-align: left;">
                        确认删除该帖子吗？
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-danger btn-sm" @click="remove_post(post)">删除</button>
                        <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">取消</button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="posts.length == 0 && now_pages == 1" class="kk9"> 暂无帖子，快去发帖吧~ 
          <div style="margin-bottom: 20px;"></div>
        </div>
        <div v-if="posts.length == 0 && now_pages > 1" class="kk9"> 我是有底线的~</div>
        <div v-if="posts.length != 0 || now_pages != 1" style="float: right; margin-right: 10px;">
          <img @click="last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
          <span style="font-size: 12px;">&nbsp; {{ now_pages }} &nbsp;</span>
          <img @click="next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
          <div style="margin-bottom: 20px;"></div>
        </div>
        <!-- Modal add_post-->
        <div class="modal fade" id="add_post" tabindex="-1">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel" style="margin-left: 10px;">新帖子~</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <label for="post_title" class="form-label">标题</label>
                  <input v-model="add_post_info.title" type="text" class="form-control" id="post_title" placeholder="在此输入标题~">
                </div>
                <div class="mb-3">
                  <label for="exampleFormControlTextarea1" class="form-label">正文</label>
                  <textarea v-model="add_post_info.content" class="form-control" id="exampleFormControlTextarea1" rows="7"
                    placeholder="在此输入正文~"></textarea>
                </div>
                <div class="kk7" @mouseover="emoji_display(0)" @mouseleave="emoji_disappear(0)">
                  <img src="../assets/emoji_light.png" style="width: 30px; height: 30px;" />
                  <EmojiPicker :native="true" @select="onSelectEmoji($event, add_post_info)" style="display: none;" id="EmojiPicker_0" class="kk12"/>
                </div>
              </div>
              <div class="modal-footer">
                <div class="err">{{ add_post_info.err_msg }}</div>
                <button type="button" class="btn btn-primary btn-sm" @click="add_post">发布</button>
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">关闭</button>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal -->
      </div>
    </div>
  </div>
</template>

<script>
// import ContentFiled from '../components/ContentFiled.vue'
import NavBar from "../components/NavBar.vue";

import { ref,reactive } from 'vue';
import $ from 'jquery';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import store from "@/store/user"
import router from '@/router/index';

import EmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';

import { base_http_url } from '@/Utils/Base_Url';


export default {
  name: 'MyInfo',
  components: {
    // ContentFiled,
    NavBar,
    EmojiPicker,

  },
  setup() {

    // Loading...
    const Loading = (type) => {
      let LoadingBlock = document.querySelector('#LoadingBlock');
      if(type == 1) {
        LoadingBlock.style.display = '';
      }
      else {
        LoadingBlock.style.display = 'none';
      }
    }

    onMounted(() => {
      refresh_posts();
    })

    const info_store = useStore();
    let posts = ref([]);
    let add_post_info = reactive({
      title: '',
      content: '',
      err_msg: '',
    });
    let user_info = reactive({
        uname: "",
        description: "",
        posts_num: "",
        friends_num: "",
        getPraises: "",
        photo: "",
    });
    let now_pages = ref(1);

    // 翻页
    const last_pages = () => {
      if(now_pages.value <= 1) return;
      now_pages.value = now_pages.value - 1;
      refresh_posts();
    }

    const next_pages = () => {
      if(posts.value.length == 0) return;
      now_pages.value = now_pages.value + 1;
      refresh_posts();
    }

    const refresh_info = () => {
      info_store.dispatch("getInfo", {
            success() {
              user_info.uname = store.state.uname;
              user_info.description = store.state.description;
              user_info.posts_num = store.state.posts_num;
              user_info.friends_num = store.state.friends_num;
              user_info.getPraises = store.state.getPraises;
              user_info.photo = store.state.photo;
            },
            error() {
            }
            
          })
    }

    const refresh_posts = () => {
      Loading(1);
      $.ajax({
        url: base_http_url + "api/user/post/getposts/",
        type: "get",
        data: {
          pages: now_pages.value,
          writer_id: store.state.id,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success(resp) {
          posts.value = resp;
          refresh_info();
          Loading(2);
        },
      });
    };


    // 添加帖子
    const add_post = () => {
      add_post_info.err_msg = "";
      $.ajax({
      url: base_http_url + "api/user/post/add/",
      type: "post",
      data: {
        title: add_post_info.title,
        content: add_post_info.content,
      },
      headers: {
        Authorization: 'Bearer ' + store.state.token,
      },
      success(resp) {
        if(resp.err_msg == 'success') {
          add_post_info.title = '';
          add_post_info.content = '';
          // 关闭模态框
          Modal.getInstance("#add_post").hide();
          refresh_posts();
        }
        else {
          add_post_info.err_msg = resp.err_msg;
        }
      },
      error(resp) {
        add_post_info.err_msg = resp.err_msg;
      }
    });
    }

    // 删除帖子
    const remove_post = (post) => {
      $.ajax({
      url: base_http_url + "api/user/post/remove/",
      type: "post",
      data: {
        id: post.id,
      },
      headers: {
        Authorization: 'Bearer ' + store.state.token,
      },
      success(resp) {
        if(resp.err_msg == 'success') {
          // 关闭模态框
          Modal.getInstance('#del_confirm_' + post.id).hide();
          refresh_posts(); 
        }
      },
      error() {
        
      }
    });
    }

    // 更新帖子
    let modify_post_errmsg = ref('');
    const update_post = (post) => {
      $.ajax({
          url: base_http_url + "api/user/post/update/",
          type: "post",
          data: {
            id: post.id,
            title: post.title,
            content: post.content,
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token,
          },
          success(resp) {
            if(resp.err_msg == 'success') {
              // 关闭模态框
              Modal.getInstance("#update_post_" + post.id).hide();
              modify_post_errmsg.value = '';
              refresh_posts();
            }
            else {
              modify_post_errmsg.value = resp.err_msg;
            }
          },
          error(resp) {
            modify_post_errmsg.value = resp.err_msg;
          }
      });
    }

    let new_uname = ref('');
    let moduname_errmsg = ref('');

    // 修改用户名
    const modify_uname = () => {
      moduname_errmsg.value = '';
      $.ajax({
        url: base_http_url + "api/user/modify/uname/",
        type: "post",
        data: {
          new_uname: new_uname.value,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success(resp) {
          if(resp.err_msg == 'success') {
            // 刷新个人信息
            info_store.dispatch("getInfo", {
              success() {
                user_info.uname = store.state.uname;
                user_info.description = store.state.description;
                user_info.posts_num = store.state.posts_num;
                user_info.friends_num = store.state.friends_num;
                user_info.getPraises = store.state.getPraises;
                user_info.photo = store.state.photo;
              },
              error() {
              }
            });
            new_uname.value = '';
          }
          else {
            moduname_errmsg.value = resp.err_msg;
          }
        },
        error(resp) {
          moduname_errmsg.value = resp.err_msg;
        }
    })
  }

  let new_description = ref('');
  let moddescription_errmsg = ref('');

  // 修改称号
  const modify_description = () => {
      moddescription_errmsg.value = '';
      $.ajax({
        url: base_http_url + "api/user/modify/description/",
        type: "post",
        data: {
          new_description: new_description.value,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success(resp) {
          if(resp.err_msg == 'success') {
            // 刷新个人信息
            info_store.dispatch("getInfo", {
              success() {
                user_info.uname = store.state.uname;
                user_info.description = store.state.description;
                user_info.posts_num = store.state.posts_num;
                user_info.friends_num = store.state.friends_num;
                user_info.getPraises = store.state.getPraises;
                user_info.photo = store.state.photo;
              },
              error() {
              }
            });
            new_description.value = '';
          }
          else {
            moddescription_errmsg.value = resp.err_msg;
          }
        },
        error(resp) {
          moddescription_errmsg.value = resp.err_msg;
        }
    })
  }

  let old_password = ref('');
  let new_password = ref('');
  let modpassword_errmsg = ref('');

  // 修改密码
  const modify_password = () => {
    modpassword_errmsg.value = '';
      $.ajax({
        url: base_http_url + "api/user/modify/password/",
        type: "post",
        data: {
          old_password: old_password.value,
          new_password: new_password.value,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success(resp) {
          if(resp.err_msg == 'success') {
            old_password.value = '';
            new_password.value = '';
            info_store.dispatch('logout');
            // 跳转到login页面
            router.push({name: 'LoginIndex'});
          }
          else {
            modpassword_errmsg.value = resp.err_msg;
          }
        },
        error(resp) {
          modpassword_errmsg.value = resp.err_msg;
        }
    })
  }

  // 修改头像
  let modphoto_errmsg = ref('');
  const modify_photo = () => {
    let formFile = document.querySelector('#formFile');
    if(formFile.files[0]) {
      upLoadPicture(formFile.files[0]);
      formFile.value = '';
    }
    else {
      modphoto_errmsg.value = '未选择文件~';
    }
  }

  // 向云端发送图片
  const upLoadPicture = (file) => {
    if(file.size > 30*1024*1024)
    {
      modphoto_errmsg.value = '图片过大~';
      return;
    }
    let formData = new FormData();
    formData.append('file',file);
    // OSS 文件上传
    $.ajax({
      url: base_http_url + "api/user/modify/photo/",
      type: "post",
      data: formData,
      headers: {
        Authorization: 'Bearer ' + store.state.token,
      },
      processData: false,
      contentType: false,
      success(resp) {
        if(resp.err_msg == 'success') {
          modphoto_errmsg.value = '';
          // 刷新个人信息
          info_store.dispatch("getInfo", {
            success() {
              user_info.uname = store.state.uname;
              user_info.description = store.state.description;
              user_info.posts_num = store.state.posts_num;
              user_info.friends_num = store.state.friends_num;
              user_info.getPraises = store.state.getPraises;
              user_info.photo = store.state.photo;
            },
            error() {
            }
          });
        }
        else {
          modphoto_errmsg.value = resp.err_msg;
        }
      },
      error(resp) {
        modphoto_errmsg.value = resp.err_msg;
      }
    });
  }


    // emoji处理
    const emoji_display = (id) => {
      let EmojiPicker = document.querySelector('#EmojiPicker_' + id);
      EmojiPicker.style.display = '';
    }

    const emoji_disappear = (id) => {
      let EmojiPicker = document.querySelector('#EmojiPicker_' + id);
      EmojiPicker.style.display = 'none';
    }

    const onSelectEmoji = (emoji, post) => {
      // 选择表情的回调函数
      post.content += emoji.i;

    }

    // 修改模块显示
    const dispaly_control1 = () => {
      let modifyPhotoBlock = document.querySelector('#modifyPhotoBlock');
      modifyPhotoBlock.style.display = '';
    }

    const disappear_control1 = () => {
      let modifyPhotoBlock = document.querySelector('#modifyPhotoBlock');
      modifyPhotoBlock.style.display = 'none';
    }

    const dispaly_control2 = () => {
      let modifyUnameBlock = document.querySelector('#modifyUnameBlock');
      modifyUnameBlock.style.display = '';
    }

    const disappear_control2 = () => {
      let modifyUnameBlock = document.querySelector('#modifyUnameBlock');
      modifyUnameBlock.style.display = 'none';
    }

    const dispaly_control3 = () => {
      let modifyDescriptionBlock = document.querySelector('#modifyDescriptionBlock');
      modifyDescriptionBlock.style.display = '';
    }

    const disappear_control3 = () => {
      let modifyDescriptionBlock = document.querySelector('#modifyDescriptionBlock');
      modifyDescriptionBlock.style.display = 'none';
    }

    const dispaly_control4 = () => {
      let modifyPasswordBlock = document.querySelector('#modifyPasswordBlock');
      modifyPasswordBlock.style.display = '';
    }

    const disappear_control4 = () => {
      let modifyPasswordBlock = document.querySelector('#modifyPasswordBlock');
      modifyPasswordBlock.style.display = 'none';
    }


  return {
      user_info,
      posts,
      add_post_info,
      add_post,
      remove_post,
      update_post,
      now_pages,
      last_pages,
      next_pages,
      modify_uname,
      new_uname,
      moduname_errmsg,
      modify_description,
      new_description,
      moddescription_errmsg,
      old_password,
      new_password,
      modpassword_errmsg,
      modify_password,
      modphoto_errmsg,
      modify_photo,
      emoji_display,
      emoji_disappear,
      onSelectEmoji,
      dispaly_control1,
      disappear_control1,
      dispaly_control2,
      disappear_control2,
      dispaly_control3,
      disappear_control3,
      dispaly_control4,
      disappear_control4,
      modify_post_errmsg,
      Loading,


    }

}
}
</script>

<style scoped>
img:hover {
  cursor: pointer;
}
.kkcol3 {
  align-self: flex-start;
  background-color: #fcfcfc; 
  margin: 20px 0; 
  margin-right: 8px;
  text-align: center; 
  /* border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; */
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.kkcol7 {
  background-color: #ffffff; 
  margin: 20px 0; 
  margin-left: 7px;
  text-align: center; 
  /* border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; */
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.kk2 {
  margin-top: 10px;
  font-weight: bold;
  font-size: 20px;
}

.kkdes {
  margin-top: 9px;
  font-size: 14px;
}

.kktag {
  float: left;
  margin-top: 15px;
  width: 33%;
  color: #9da5ad;
  margin-bottom: 15px;
}

.kkset {
  font-weight: bold;
  text-align: left;
  margin-top: 10px;
  border-bottom: 1px solid #d4d6dc;
}

.kkitem {
  text-align: left; 
  margin-top: 5px;
  border-bottom: 1px solid #d4d6dc;
}

.kkinput {
  height: 24px; 
  font-size: 12px; 
  line-height: 10px;
  margin-bottom: 5px;
}

.kk7
{
  position: relative;
  height: 40px;
  width: 40px;
  text-align: left;
}

.kk9{
  text-align: center;
  color: rgb(254, 81, 81);
  font-size: 13px;
}

.kk12
{
  position: absolute;
  top: -320px;
}

.err {
  color: rgb(254, 81, 81);
  font-size: 12px;
}

</style>