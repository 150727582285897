<template>
<nav class="navbar navbar-expand-lg navbar-dark ">
  <div class="container">
    <router-link class="navbar-brand" :to="{name: 'home'}">kkChat</router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item" v-if="$store.state.user.is_login">
          <router-link :class="route_name == 'ChatBlockIndex'? 'nav-link active':'nav-link'" aria-current="page" :to="{name: 'ChatBlockIndex'}">chat</router-link>
        </li>
        <li class="nav-item">
          <router-link :class="route_name == 'WorldBlockIndex'? 'nav-link active':'nav-link'" :to="{name: 'WorldBlockIndex'}">世界</router-link>
        </li>
        <li class="nav-item dropdown" v-if="$store.state.user.is_login == true">
          <router-link :class="route_name == 'MyInfoIndex' || route_name == 'MySpaceBlockIndex' ? 'nav-link dropdown-toggle active':'nav-link dropdown-toggle'" role="button" data-bs-toggle="dropdown" aria-expanded="false" :to="{name: 'MyInfoIndex'}">
            我的
          </router-link>
          <ul class="dropdown-menu">
            <li><router-link class="dropdown-item" :to="{name: 'MyInfoIndex'}">个人信息</router-link></li>
            <li><router-link class="dropdown-item" :to="{name: 'MySpaceBlockIndex'}">好友动态</router-link></li>
            <li><hr class="dropdown-divider"></li>
            <li><router-link @click="logout" class="dropdown-item" :to="{name: 'LoginIndex'}">退出</router-link></li>
          </ul>
        </li>
        <li class="nav-item" v-if="$store.state.user.is_login == false">
          <router-link class="nav-link" :to="{name: 'LoginIndex'}">登录</router-link>
        </li>
        <li class="nav-item" v-if="$store.state.user.is_login == false">
          <router-link class="nav-link" :to="{name: 'RegisterIndex'}">注册</router-link>
        </li>
        <li class="nav-item" v-if="$store.state.user.uname != 'kk'">
          <a class="nav-link disabled">尽请期待</a>
        </li>
        <li class="nav-item dropdown" v-else>
          <router-link :class="route_name == 'KKRootUserBlock' || route_name == 'KKRootPostBlock' || route_name == 'KKRootChatInfoBlock' ? 'nav-link dropdown-toggle active':'nav-link dropdown-toggle'" role="button" data-bs-toggle="dropdown" aria-expanded="false" :to="{name: 'KKRootUserBlock'}">
            管理员
          </router-link>
          <ul class="dropdown-menu">
            <li><router-link class="dropdown-item" :to="{name: 'KKRootUserBlock'}">用户管理</router-link></li>
            <li><router-link class="dropdown-item" :to="{name: 'KKRootPostBlock'}">帖子管理</router-link></li>
            <li><router-link class="dropdown-item" :to="{name: 'KKRootChatInfoBlock'}">聊天管理</router-link></li>
          </ul>
        </li>
      </ul>
      <div class="d-flex" v-if="route_name == 'WorldBlockIndex'">
        <input v-model="post_message" class="form-control me-2" style="height: 30px; margin-top: -3px; font-size: 13px;" placeholder="在世界中搜索~">
        <button class="btn btn-outline-info kk1" @click="WorldSearch" style="white-space: nowrap; margin-left: 5px;">搜索</button>
      </div>
      <div class="d-flex" v-if="route_name == 'MySpaceBlockIndex'">
        <input v-model="user_message" class="form-control me-2" style="height: 30px; margin-top: -3px; font-size: 13px;" placeholder="搜索用户~">
        <button class="btn btn-outline-info kk1" @click="UserSearch" style="white-space: nowrap; margin-left: 5px;">搜索</button>
      </div>
    </div>
  </div>
</nav>
</template>

<script>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useStore } from 'vuex';
// import router from '@/router/index';
import { ref } from 'vue';

export default{
    name: 'NavBar',
    setup(props, context){
        const store = useStore();
        const route = useRoute();
        let route_name = computed(() => route.name)
        const logout = () => {
          store.dispatch("logout");
        }

        // // 跳转管理页面
        // const toRoot = (type) => {
        //     router.push({
        //       name: 'KKRootBlock',
        //       params: {
        //         type: type,
        //       },
        //   });
        // }

        // 世界中搜索
        let post_message = ref('');
        const WorldSearch = () => {
          context.emit('worldsearch', post_message.value);
          post_message.value = '';
        }

        // 搜索用户
        let user_message = ref('');
        const UserSearch = () => {
          context.emit('usersearch', user_message.value);
          // user_message.value = '';
        }

        return {
            route_name,
            logout,
            // toRoot,
            post_message,
            WorldSearch,
            user_message,
            UserSearch,
        }
    }
}
</script>

<style scoped>
li:hover {
  cursor: pointer;
}
.navbar
{
    background-color: rgba(74, 106, 118, 0.987);
}


.nav-item 
{
  margin: 0 30px;
}

.nav-link:hover
{
    background-color:rgb(39, 150, 175);
}

.kk1{
  margin-top: -3px;
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 16px;
  font-size: 13px;
  color:azure;
}

.kk1:hover {
  color:azure;
}

</style>

