// import $ from 'jquery';

export default {
    // 存储全局信息
    state: {
        socket: null,
        chat_id: "",
        chat_uname: "",
        chat_photo: "",

    },
    getters: {
    },
    // 用于修改数据
    mutations: {
        updateSocket(state, socket) {
            state.socket = socket;
        },
        updateChatInfo(state, data) {
            state.chat_id = data.chat_id;
            state.chat_uname = data.chat_uname;
            state.chat_photo = data.chat_photo;
        }
    },
    // 定义登录函数
    actions: {

    },
    modules: {
    }
}