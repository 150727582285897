<template>
    <div class="form-div">
        <div class="reg-content">
            <div class="kk2">欢迎注册</div>
            <div class="kk3">已有帐号？
                <router-link :to="{ name: 'LoginIndex' }">登录</router-link>
            </div>
        </div>
        <form @submit.prevent="register" id="reg-form" method="get">
            <div class="err">{{ err_msg }}</div>
            <p class="kk1">用户名:<input v-model="uname" id="username" name="username" type="text"></p>
            <p class="kk1">密&nbsp;&nbsp;&nbsp;码:<input v-model="password" id="password" name="password" type="password"></p>
            <div class="buttons">
                <input value="注 册" type="submit" id="reg_btn">
            </div>
            <br class="clear">
        </form>

    </div>
</template>
    
<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
import router from '@/router/index';


export default {
    name: 'RegisterView',
    components: {

    },
    setup() {
    const store = useStore();
    let uname = ref('');
    let password = ref('');
    let err_msg = ref('');
    const register = () => {
      err_msg.value = "",
      store.dispatch("register",{
        uname: uname.value,
        password: password.value,
        success() {
            // 跳转到login页面
            router.push({name: 'LoginIndex'});
        },
        error(resp) {
          err_msg.value = resp.err_msg;
        }
      })
    }

    return {
      uname,
      password,
      err_msg,
      register,
    }


  }
    

}
</script>
    
<style scoped>
.kk1 {
    height: 65px;
    text-align: center;
    line-height: 65px;
}

.kk2 {
    text-align: left;
    font-size: 40px;
    margin-top: -20px;
    margin-bottom: 20px;
}

.err {
  color: rgb(254, 81, 81);
  text-align: center;
  font-size: 15px;
  height: 15px;
  margin-top: -15px;
}

* {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.reg-content {
    padding: 30px;
    margin: 3px;
}

a,
img {
    border: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
    height: 90px;

}

.inputs {
    vertical-align: top;
}

.clear {
    clear: both;
}

.clear:before,
.clear:after {
    content: "";
    display: table;
}

.clear:after {
    clear: both;
}

.form-div {
    background-color: rgba(255, 255, 255, 0.27);
    border-radius: 10px;
    border: 1px solid #aaa;
    width: 400px;
    margin-top: 100px;
    margin-left: 700px;
    padding: 30px 0 20px 0px;
    font-size: 16px;
    box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.5), 0px 0px 15px rgba(75, 75, 75, 0.3);
    text-align: left;
}

.form-div input[type="text"],
.form-div input[type="password"],
.form-div input[type="email"] {
    width: 268px;
    margin: 10px;
    line-height: 20px;
    font-size: 16px;
}

.form-div input[type="checkbox"] {
    margin: 20px 0 20px 10px;
}

.form-div input[type="button"],
.form-div input[type="submit"] {
    margin: 10px 20px 0 0;
}

.form-div table {
    margin: 0 auto;
    text-align: right;
    color: rgba(64, 64, 64, 1.00);
}

.form-div table img {
    vertical-align: middle;
    margin: 0 0 5px 0;
}

.footer {
    color: rgba(64, 64, 64, 1.00);
    font-size: 12px;
    margin-top: 30px;
}

.form-div .buttons {
    float: right;
}

input[type="text"],
input[type="password"],
input[type="email"] {
    border-radius: 8px;
    box-shadow: inset 0 2px 5px #eee;
    padding: 10px;
    border: 1px solid #D4D4D4;
    color: #333333;
    margin-top: 5px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus {
    border: 1px solid #50afeb;
    outline: none;
}

input[type="button"],
input[type="submit"] {
    padding: 7px 15px;
    background-color: #3c6db0;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    min-width: 80px;
    border: none;
    color: #FFF;
    box-shadow: 1px 1px 1px rgba(75, 75, 75, 0.3);
}

input[type="button"]:hover,
input[type="submit"]:hover {
    background-color: #5a88c8;
}

input[type="button"]:active,
input[type="submit"]:active {
    background-color: #5a88c8;
}

.err_msg {
    color: red;
    padding-right: 170px;
}

#password_err,
#tel_err {
    padding-right: 195px;
}

#reg_btn {
    margin-right: 50px;
    width: 285px;
    height: 45px;
    margin-top: 20px;
}
</style>
