<template>
  <NavBar></NavBar>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <div class="kkf">
        <div style=" margin-top: 25px; margin-left: 5px; padding: 0 10px;">
          <img :src="info_store.state.user.photo" alt="" style="float: left; height: 40px;">
          <div style="display: inline-block; margin-left: 13px; margin-bottom: 15px;">
            <div style="font-weight: bold;"> {{ info_store.state.user.uname }}</div>
            <div style="font-size: 13px;"> {{ info_store.state.user.description }}</div>
          </div>
          <div style="margin-bottom: 20px;">
            <input @input="FriendsSearch" v-model="friends_search_msg" class="kks" type="text" placeholder="搜索好友~" >
            <img @click="FriendsSearch" src="../assets/查找.png" alt="查找" style="width: 20px; margin-left: 8px;">
          </div>

        </div>
        <div v-for="friend in friends" :key="friend.id" id="friendsShowDiv">
          <div class="kkli" :style="friend.id == chat_id ? 'background-color: #c6c5c6;' : ''" @click="chatInit($event, friend)" :id="'kkli' + friend.id">
            <span class="position-relative">
              <img :src="friend.friend_photo" alt="" style="float: left; height: 40px; width: 40px;">
              <span class="position-absolute translate-middle badge rounded-pill bg-danger kkbadgenum">
                <span v-if="friend.unread_msg > 0 && friend.unread_msg < 100"> {{ friend.unread_msg }} </span>
                <span v-if="friend.unread_msg >= 100"> 99+ </span>
              </span>
            </span>
            
            <div style="display: inline-block; margin-left: 13px; margin-bottom: 15px;">
              <div style="font-weight: bold;"> {{ friend.friend_uname }}</div>
              <div style="font-size: 13px;"> {{ friend.friend_description }}</div>
            </div>
            <img src="../assets/删除.png" alt="" style="float: right; width: 20px;" data-bs-toggle="modal" :data-bs-target="'#del_confirm_' + friend.id">
          </div>

          <!-- 删除好友确认 Modal -->
          <div class="modal fade" :id="'del_confirm_' + friend.id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">删除好友~</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="text-align: left;">
                  确认删除该好友吗？
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger btn-sm" @click="remove_friend(friend)">删除</button>
                  <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">取消</button>
                </div>
              </div>
            </div>
          </div>

        </div>


        <div v-if="friends.length == 0 && now_pages == 1">
            <div class="kk3"> 暂无好友，快去世界中寻找好友吧~ </div>
        </div>
        <div v-if="friends.length == 0 && now_pages > 1">
            <div class="kk3"> 到底啦~</div>
        </div>
        <div v-if="friends.length != 0 || now_pages != 1" style="float: right; margin-right: 10px;">
                <img @click="last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
                <span style="font-size: 12px;">&nbsp; {{ now_pages }} &nbsp;</span>
                <img @click="next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
        </div>
      </div>
      <div class="kkcontainer">
          <div class="">
            <div style="margin-bottom: 10px; text-align: center;"> {{ chat_uname }} 
              <img v-if="chat_id > 0" src="../assets/删除帖子.png" alt="删除聊天记录" style="float: right; width: 20px; margin-right: 10px;" data-bs-toggle="modal" data-bs-target="#del_chatinfo_confirm">
              <!-- 删除聊天记录确认 Modal -->
              <div class="modal fade" id="del_chatinfo_confirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="staticBackdropLabel">删除聊天记录~</h1>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" style="text-align: left;">
                      确认删除所有聊天记录吗？
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger btn-sm" @click="delete_mychatInfo">删除</button>
                      <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">取消</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chat-box" id="chatBox"></div>
          <div class="kk6">
            <div class="kk7" @mouseover="emoji_display" @mouseleave="emoji_disappear">
            <img src="../assets/emoji_light.png" style="width: 30px; height: 30px;" />
            <EmojiPicker :native="true" @select="onSelectEmoji" style="display: none;" id="EmojiPicker" class="kk8"/>
            </div>
            <div class="kk7">
              <label for="fileInput">
                <img src="../assets/file.png" style="width: 30px; height: 30px;" />
              </label>
              <input type="file" id="fileInput" ref="fileInput" @change="onFileChange" style="display: none" />
            </div>
            <div class="kk7">
              <img data-bs-toggle="modal" data-bs-target="#VideoCallModal" src="../assets/视频通话.png" style="width: 30px; height: 30px;" />
            </div>
            <div class="err_chat">{{ chat_err_msg }}</div>
          </div>


          <div class="">
            <div class="form-floating">
              <textarea v-model="chat_msg" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 150px"></textarea>
              <label for="floatingTextarea2">kkChat Here~</label>
            </div>
            <button class="btn btn-outline-secondary kk5" type="button" id="button-addon2" @click="chatSendMsg">发送</button>
            
          </div>
      </div>

      <!-- Button trigger modal -->
      <button style="display: none;" type="button" id="pictureButton" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#pictureDetail">
        Launch picture modal
      </button>

      <a href="" id="doDownload" style="display: none;" download="kk.png"></a>

      <!-- 放大显示图片 Modal -->
      <div class="modal fade" id="pictureDetail" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="FileNameDisplay">未命名~</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div style="height: 350px; background-color: rgb(245, 245, 245);text-align: center;">
                    <img id="ModalImg" src="" alt="" style="width: auto; height: 200%; max-height: 100%; max-width: 100%;" />
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm" @click="downloadPicture">下载</button>
                  <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">关闭</button>
                </div>
              </div>
            </div>
      </div>

      <!-- 视频通话 Modal -->
      <div class="modal fade" id="VideoCallModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">视频通话</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="text-align: left;">
                  <video-call  ref="kkVideoCall" :chat_id="chat_id" :socket="socket" ></video-call>
                </div>
                <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-danger btn-sm" @click="remove_friend(friend)">删除</button>
                  <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">取消</button>
                </div> -->
              </div>
            </div>
      </div>

    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";

import store from '@/store/user';
import $ from 'jquery';
import { ref } from 'vue';
import {onUnmounted} from 'vue';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap/dist/js/bootstrap';


import EmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';
import VideoCall from '../components/VideoCall'

import { base_http_url } from '@/Utils/Base_Url';
import { base_ws_url } from "@/Utils/Base_Url";



export default {
  name: 'ChatBlock',
  components: {
    NavBar,
    EmojiPicker,
    VideoCall,

  },
  setup() {
    const info_store = useStore();
    let chat_err_msg = ref('');
    let chat_msg = ref('');
    let chat_id = ref(0);
    let chat_uname = ref('快去和好友聊天吧~');
    let chat_photo = ref('');
    let friends = ref([]);
    let now_pages = ref(1);
    const wsUrl = base_ws_url + "websocket/" + store.state.token
    let socket = ref(null);

    // 翻页
    const last_pages = () => {
      if(now_pages.value <= 1) return;
      now_pages.value = now_pages.value - 1;
      FriendsSearch();
    }

    const next_pages = () => {
      if(friends.value.length == 0) return;
      now_pages.value = now_pages.value + 1;
      FriendsSearch();
    }

    // 聊天时间显示
    let time_display = ref(0);

    const formatTime = (chatTime) => {
      const now = new Date();
      const min15 = 60*15*1000;

      if(chatTime.getTime() - time_display.value < min15) {
        time_display.value = chatTime.getTime();
        return '';
      }
      time_display.value = chatTime.getTime();
      let nowYear = now.getFullYear();
      let chatYear = chatTime.getFullYear();
      let nowMonth = now.getMonth() + 1;
      let chatMonth = chatTime.getMonth() + 1;
      let nowDate = now.getDate();
      let chatDate = chatTime.getDate();
      let nowDay = now.getDay();
      let chatDay = chatTime.getDay();
      // let nowHours = now.getHours();
      let chatHours = chatTime.getHours();
      // let nowMinutes = now.getMinutes();
      let chatMinutes = chatTime.getMinutes();

      let day = ['日 ','一 ','二 ','三 ','四 ','五 ','六 '];
      let outHours = chatHours;
      if(chatHours < 10) outHours = '0' + outHours;
      let outMinutes = chatMinutes;
      if(chatMinutes < 10) outMinutes = '0' + outMinutes;

      // 往年消息
      if(nowYear != chatYear) return chatYear + '年' + chatMonth + '月' + chatDate + '日 ' + outHours + ':' + chatMinutes;
      // 本年往月消息
      if(nowMonth != chatMonth) return chatMonth + '月' + chatDate + '日 ' + outHours + ':' + outMinutes;
      // 昨天、前天消息
      if(nowDate - chatDate == 1) return '昨天 ' + outHours + ':' + outMinutes;
      if(nowDate - chatDate == 2) return '前天 ' + outHours + ':' + outMinutes;
      // 本周消息
      if(nowDay > chatDay && nowDate - chatDate <= 6 && nowDate - chatDate > 0) return '周' + day[chatDay] + outHours + ':' + outMinutes;
      // 当日消息
      if(nowDate == chatDate) return outHours + ':' + outMinutes;

      return chatMonth + '月' + chatDate + '日 ' + outHours + ':' + outMinutes;

    }


    // // 刷新好友列表
    // const refresh_friends = () => {
    //   $.ajax({
    //     url: base_http_url + "api/user/friend/getfriends/",
    //     type: "post",
    //     data: {
    //       pages: now_pages.value,
    //     },
    //     headers: {
    //       Authorization: 'Bearer ' + store.state.token,
    //     },
    //     success(resp) {
    //       friends.value = resp;
    //     },
    //   });
    // }

    // refresh_friends();

    // 搜索好友
    let friends_search_msg = ref('');
    const FriendsSearch = () => {
      $.ajax({
        url: base_http_url + "api/user/friend/search/",
        type: "post",
        data: {
          pages: now_pages.value,
          message: friends_search_msg.value,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success(resp) {
          friends.value = resp;
        },
        error() {
        }
      });
    };

    FriendsSearch();

    // 关闭聊天
    const chatClose = () => {
      socket.value = null;
      time_display.value = 0;
      chat_id.value = 0;
      chat_err_msg.value = '';
      chat_msg.value = '';
      chat_photo.value = '';
      FriendsSearch();
    }

    // 删除好友
    const remove_friend = (friend) => {
      $.ajax({
      url: base_http_url + "api/user/friend/remove/",
      type: "post",
      data: {
        id: friend.id,
      },
      headers: {
        Authorization: 'Bearer ' + store.state.token,
      },
      success() {
        Modal.getInstance("#del_confirm_" + friend.id).hide();
        if(chat_id.value == friend.id) {
          chatClose();
          chat_uname.value = '快去和好友聊天吧~';
          chat_id.value = 0;
          let chatBox = document.querySelector('#chatBox');
          chatBox.innerHTML = '';
        }
        FriendsSearch();
      },
      error() {
      }
    });
    }

    // 初始化聊天信息
    const kkVideoCall = ref();
    const chatInit = (event, friend) => {
      if(event.target.tagName == 'IMG') return;
      let chatli = document.querySelector('#kkli' + chat_id.value);
      if(chatli) chatli.style.backgroundColor = '';
      let kkli = document.querySelector('#kkli' + friend.id);
      kkli.style.backgroundColor = '#c6c5c6';

      if(chat_id.value != friend.id)
      {
        time_display.value = 0;
        FriendsSearch();
        if(socket.value) chatClose();
        let chatBox = document.querySelector('#chatBox');
        chatBox.innerHTML = '';
        socket.value = new WebSocket(wsUrl + '/' + friend.id);
        chat_err_msg.value = '';
        chat_id.value = friend.id;
        chat_uname.value = friend.friend_uname;
        chat_photo.value = friend.friend_photo;
        info_store.commit('updateSocket',socket.value);
        info_store.commit('updateChatInfo',{
          chat_id: chat_id.value,
          chat_uname: chat_uname.value,
          chat_photo: chat_photo.value,
        });
        chatBox.addEventListener('click', bigPicture);

        // 当接收到服务器发送的信息时触发
        socket.value.onmessage = msg => {
          FriendsSearch();
          const data = JSON.parse(msg.data);
          // 视频通话相关信息
          if(data.length && (data[0].ws_state != undefined && data[0].ws_state != "聊天")){
            if(data[0].ws_state == "对方不在线") console.log(data[0].ws_state, kkVideoCall.value)
            else if(data[0].ws_state == "等待接收视频邀请") kkVideoCall.value.acceptCall();
            else if(data[0].ws_state == "等待发送offer") kkVideoCall.value.sendVideoCallOffer();
            else if(data[0].ws_state == "等待发起answer") kkVideoCall.value.receiveVideoCallOffer();
            else if(data[0].ws_state == "等待接收answer") kkVideoCall.value.receiveVideoCallAnswer(JSON.parse(data[0].file_name));
            else if(data[0].ws_state == "等待接收candidate") kkVideoCall.value.receiveVideoCallCandidate(JSON.parse(data[0].file_name));


            return;
          }
          for(let i=0;i<data.length;i++)
          {
            if(data[i].msg_type == 0) {
              chat_err_msg.value = data[i].message;
              FriendsSearch();
            }
            else if(data[i].from_id == chat_id.value) {
              changeChatTime(new Date(data[i].send_time));
              if(data[i].msg_type == 1) changeMsgFriend(data[i].message);
              else changeFileFriend(data[i].message, data[i].msg_type, data[i].file_name);
            }
            else if(data[i].from_id == store.state.id) {
              changeChatTime(new Date(data[i].send_time));
              if(data[i].msg_type == 1) changeMsgMe(data[i].message);
              else changeFileMe(data[i].message, data[i].msg_type, data[i].file_name);
            }

          }
        }
        socket.value.onclose = () => {
          chatClose();
        }
      }
    }

    // emoji处理
    const emoji_display = () => {
      let EmojiPicker = document.querySelector('#EmojiPicker');
      EmojiPicker.style.display = '';
    }

    const emoji_disappear = () => {
      let EmojiPicker = document.querySelector('#EmojiPicker');
      EmojiPicker.style.display = 'none';
    }

    const onSelectEmoji = (emoji) => {
      // 选择表情的回调函数
      chat_msg.value += emoji.i;
    }


    // 渲染聊天时间
    function changeChatTime(time) {
      let chat_time = formatTime(time);
      if(!chat_time) return;
      let chatBox = document.querySelector('#chatBox');
      let receiveTime = '<div style="text-align: center;"><span style="background-color: #dadada; text-align: center; color: #fff; padding: 3px 3px; border-radius: 3px; font-size: 10px;">' + chat_time + '</span></div>';
      chatBox.innerHTML += receiveTime;
      chatBox.scrollTop = chatBox.scrollHeight;
    }

    // 渲染自己发送的聊天记录
    function changeMsgMe(message) {
      let chatBox = document.querySelector('#chatBox');
      let receiveMsg = '<div style="position: relative; text-align: right; width: 100%; padding: 10px 0;"><div style="display: inline-block; background-color: rgb(152, 225, 101); color: rgb(36, 36, 59); border-radius: 5px; padding: 3px 8px; text-align: left; max-width: 80%; word-break: break-all;"></div></div>';
      chatBox.innerHTML += receiveMsg;
      chatBox.lastElementChild.lastElementChild.innerText = message;
      chatBox.lastElementChild.innerHTML += '<img style="position: absolute; top: 5px; margin: 0 7px; width:40px;" src="' + store.state.photo + '" alt=""><div style="display: inline-block; width: 40px; height: 40px; margin: 0 7px">';
      chatBox.scrollTop = chatBox.scrollHeight;
    }

    // 渲染好友发送的聊天记录
    function changeMsgFriend(message) {
      let chatBox = document.querySelector('#chatBox');
      let receiveMsg = '<div style="position: relative; text-align: left; width: 100%; padding: 10px 0; word-break: break-all;"><div style="display: inline-block; background-color: rgb(255, 255, 255); color: rgb(36, 36, 59); border-radius: 5px; padding: 3px 8px; text-align: left; max-width: 80%; word-break: break-all;"></div></div>';
      chatBox.innerHTML += receiveMsg;
      chatBox.lastElementChild.lastElementChild.innerText = message;
      chatBox.lastElementChild.innerHTML = '<img style="position: absolute; top: 5px; margin: 0 7px; width:40px;" src="' + chat_photo.value + '" alt=""><div style="display: inline-block; width: 40px; height: 40px; margin: 0 7px"></div>' + chatBox.lastElementChild.innerHTML;
      chatBox.scrollTop = chatBox.scrollHeight;
    }

    // 发送消息
    const chatSendMsg = () => {
      if(socket.value) {
        chat_msg.value = chat_msg.value.trim();
        if(chat_msg.value != '')
        {
          if(chat_msg.value.length > 10000) {
            chat_err_msg.value = '文字太长~';
            return;
          }
          chat_err_msg.value = '';
          let data = {
            toId: chat_id.value,
            message: chat_msg.value,
            msg_type: 1,
            file_name: '文字信息~',
            ws_type: "聊天",
          };
          socket.value.send(JSON.stringify(data));
          chat_msg.value = '';
        }
      }
    }

    // 文件上传处理
    const onFileChange = (event) => {
      if(event.target.files[0]) {
        upLoadFile(event.target.files[0]);
        event.target.value = '';
      }
    }

    // 向云端发送文件
    const upLoadFile = (file) => {
      if(socket.value) 
      {
        if(file.size > 30*1024*1024)
        {
          chat_err_msg.value = '文件过大~';
        }
        else
        {
          let formData = new FormData();
          formData.append('file',file);
          // OSS 文件上传
          $.ajax({
            url: base_http_url + "api/chat/sendFile/",
            type: "post",
            data: formData,
            headers: {
              Authorization: 'Bearer ' + store.state.token,
            },
            processData: false,
            contentType: false,
            success(resp) {
              if(resp.err_msg == 'success') {
                chat_err_msg.value = '';
                sendFileUrl(resp.fileUrl, resp.type, resp.fileName);
              }
              else {
                chat_err_msg.value = '发送失败~'
              }
            },
            error() {
              chat_err_msg.value = '发送失败~';
            }
          });

          
        }
      }
    }


    // 发送文件url地址
    const sendFileUrl = (url,type, fileName) => {
      if(socket.value) {
        let data = {
          toId: chat_id.value,
          message: url,
          msg_type: type,
          file_name: fileName,
          ws_type: "聊天",
        };
        socket.value.send(JSON.stringify(data));
      }
    }

    // 渲染自己发送的文件记录
    function changeFileMe(url, type, fileName) {
      let chatBox = document.querySelector('#chatBox');
      let receiveMsg = '';

      if(type == 2)
      {
        // 图片
        receiveMsg = '<div style="position: relative; text-align: right; width: 100%; margin: 10px 0;"><img style="display: inline-block; padding: 8px 4px; max-width: 60%;" src="' + url + '" alt="' + fileName + '"></div>';
      }

      else if(type == 3) 
      {
        // 文件
        receiveMsg = '<div style="position: relative; text-align: right; width: 100%; margin: 10px 0;"><a href="' + url + '" style="display: inline-flex; margin: 10px 4px; max-width: 35%; height: 80px; text-align: left; text-decoration: none; color: black; border-radius: 3px; background-color: #fff; border: 1px solid rgb(230, 230, 230); " download=""><span style="display: inline-block; background-image: url(https://web-kkchat.oss-cn-hangzhou.aliyuncs.com/file.png); background-size: cover; width: 50px; height: 50px; margin: 15px 5px;"></span><span style="display: inline-block; height: 50px; text-align: center; margin: 5px 15px; margin-top: 20px; padding: 0px 0; max-width: 130px; overflow: hidden;">' + fileName + '</span></a></div>';
      }

      chatBox.innerHTML += receiveMsg;
      chatBox.lastElementChild.innerHTML += '<img style="position: absolute; top: 5px; margin: 0 7px; width:40px;" src="' + store.state.photo + '" alt=""><div style="display: inline-block; width: 40px; height: 40px; margin: 0 7px">';
      chatBox.scrollTop = chatBox.scrollHeight;
    }

    // 渲染好友发送的文件记录
    function changeFileFriend(url, type, fileName) {
      let chatBox = document.querySelector('#chatBox');
      let receiveMsg = '';

      if(type == 2)
      {
        // 图片
        receiveMsg = '<div style="position: relative; text-align: left; width: 100%; margin: 10px 0;"><img style="display: inline-block; padding: 8px 4px; max-width: 60%;" src="' + url + '" alt="' + fileName + '"></div>';
      }

      else if(type == 3) 
      {
        // 文件
        receiveMsg = '<div style="position: relative; text-align: left; width: 100%; margin: 10px 0;"><a href="' + url + '" style="display: inline-flex; margin: 10px 4px; max-width: 35%; height: 80px; text-align: left; text-decoration: none; color: black; border-radius: 3px; background-color: #fff; border: 1px solid rgb(230, 230, 230); " download=""><span style="display: inline-block; background-image: url(https://web-kkchat.oss-cn-hangzhou.aliyuncs.com/file.png); background-size: cover; width: 50px; height: 50px; margin: 15px 5px;"></span><span style="display: inline-block; height: 50px; text-align: center; margin: 5px 15px; margin-top: 20px; padding: 0px 0; max-width: 130px; overflow: hidden;">' + fileName + '</span></a></div>';
      }
      
      
      chatBox.innerHTML += receiveMsg;
      chatBox.lastElementChild.innerHTML = '<img style="position: absolute; top: 5px; margin: 0 7px; width:40px;" src="' + chat_photo.value + '" alt=""><div style="display: inline-block; width: 40px; height: 40px; margin: 0 7px"></div>' + chatBox.lastElementChild.innerHTML;
      chatBox.scrollTop = chatBox.scrollHeight;
    }

    // 图片的放大查看、下载
    let pictureUrl = ref('');
    let pictureName = ref('');
    function bigPicture(event) {
      if(event.target.tagName != 'IMG') return;
      pictureUrl.value = event.target.src;
      pictureName.value = event.target.alt;
      let ModalImg = document.querySelector('#ModalImg');
      ModalImg.src = pictureUrl.value;
      let FileNameDisplay = document.querySelector('#FileNameDisplay');
      FileNameDisplay.innerText = pictureName.value;
      let pictureButton = document.querySelector('#pictureButton');
      pictureButton.click();

    }
    
    const downloadPicture = () => {
      let doDownload = document.querySelector('#doDownload');
      doDownload.download = pictureName.value;
      doDownload.href = pictureUrl.value;
      doDownload.click();
    }

    onUnmounted(() => {
      if(socket.value) socket.value.close();
    });

    // 删除聊天记录
    const delete_mychatInfo = () => {
      if(socket.value) {
        $.ajax({
          url: base_http_url + "api/chat/delete/",
          type: "post",
          data: {
            id: chat_id.value,
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token,
          },
          success(resp) {
            Modal.getInstance("#del_chatinfo_confirm").hide();
            if(resp.err_msg == 'success') {
              let chatBox = document.querySelector('#chatBox');
              chatBox.innerHTML = '';
            }
          },
          error() {

          }
        });
      }
    }



    return {
      info_store,
      chat_id,
      chat_uname,
      friends,
      // refresh_friends,
      remove_friend,
      now_pages,
      last_pages,
      next_pages,
      chatInit,
      chat_msg,
      chatSendMsg,
      onSelectEmoji,
      emoji_display,
      emoji_disappear,
      onFileChange,
      chat_err_msg,
      bigPicture,
      downloadPicture,
      friends_search_msg,
      FriendsSearch,
      formatTime,
      time_display,
      changeChatTime,
      delete_mychatInfo,
      socket,
      kkVideoCall,

    }

  },

}
</script>

<style scoped>
img:hover {
  cursor: pointer;
}

.kkf {
  float: left;
  width: 20%;
  height: 840px;
  margin: 20px 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

#friendsShowDiv {
  border-top: 1px solid rgb(199, 199, 199);
  padding: 5px 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

#friendsShowDiv::-webkit-scrollbar {
    width: 5px;
}

#friendsShowDiv::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

#friendsShowDiv::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 5px;
}

.kkli {
  margin-left: 1px;
  margin-right: 1px;
  padding: 10px 10px; 
  padding-bottom: 5px;
}


.kkli:hover {
  background-color: #d9d8d8;
}

.kks {
  display: inline-block;
  height: 28px;
  width: 80%;
  padding-left: 5px;
  margin-left: -7px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1.8vh;
  background-color: #D8D9D8;
}

.kkcontainer {
  float: right;
  width: 80%;
  height: 840px;
  margin: 20px 0;
  padding: 20px;
  background-color: #fbfbfb;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
.chat-box {
  height: 500px;
  margin-bottom: 10px;
  padding: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.chat-box::-webkit-scrollbar {
    width: 5px;
}

.chat-box::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.chat-box::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 5px;
}

.kkbadgenum {
  top: -22px;
  left: 0px;
  font-size: 10px;
  padding: 0.2em 0.45em;
}

.kk3{
  text-align: center;
  font-size: 12px;
  color: rgb(254, 81, 81);
}

.kk5 
{
  float: right;
  margin: 15px 20px;
}

.kk6 {
  height: 40px;
  text-align: left;
}


.kk7
{
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;
  text-align: left;
  margin-right: 10px;
}

.kk8
{
  position: absolute;
  top: -320px;
}


.err_chat {
  float: right;
  color: rgb(254, 81, 81);
  text-align: right;
  font-size: 15px;
  height: 15px;
  margin-right: 10px;
  margin-top: 10px;
}


</style>