<template>
    <div class="flex items-center flex-col text-center p-12 h-screen">
      <div class="relative h-full mb-4">
        <video
          ref="localVideo"
          id="localVideoId"
          style="height: 450px; width: 780px;"
        ></video>
        <video
          ref="remoteVideo"
          id="remoteVideoId"
          class="w-32 h-48 absolute bottom-0 right-0 object-cover"
        ></video>
        <div v-if="caller && calling" class="absolute top-2/3 left-36 flex flex-col items-center">
          <p class="mb-4 text-white">等待对方接听...</p>
          <img @click="hangUp" src="../assets/refuse.svg" class="w-16 cursor-pointer" alt="">
        </div>
        <div v-if="called && calling" class="absolute top-2/3 left-32 flex flex-col items-center">
          <p class="mb-4 text-white">收到视频邀请...</p>
          <div class="flex">
            <img @click="hangUp" src="../assets/refuse.svg" class="w-16 cursor-pointer mr-4" alt="">
            <img @click="acceptCall" src="../assets/accept.svg" class="w-16 cursor-pointer" alt="">
          </div>
        </div>
      </div>
      <div class="flex gap-2 mb-4">
        <button 
          class="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white" 
          @click="callRemote"
        >发起视频</button>
        <button 
          class="rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white" 
          @click="hangUp"
        >挂断视频</button>
      </div>
    </div>
  </template>

<script>
import { ref } from 'vue'


export default {
    name: 'VideoCall',
    props:['chat_id','socket'],
    setup(props){
      // 状态
      const called = ref(false)                  // 是否是接收方
      const caller = ref(false)                  // 是否是发起方
      const calling = ref(false)                 // 呼叫中
      const communicating = ref(false)           // 视频通话中
      const localVideo = ref()                   // video标签实例，播放本人的视频
      const remoteVideo = ref()                  // video标签实例，播放对方的视频
      let peer = ref(null)
      let localStream = ref(undefined)
      let offer = ref(null)
      let answer = ref(null)

      // 获取本地音视频流
      const getLocalStream = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true
        }).then((stream) => {
          // 本地视频标签进行播放
          localVideo.value.srcObject = stream;
          let playPromise = localVideo.value.play();
          if (playPromise != undefined) {
            playPromise.then(()=>{
              localVideo.value.play();
            })
          }
          localStream.value = stream;
        })
        return stream
      }

      // 发起方发起视频请求
      const callRemote = async () => {
        caller.value = true;
        calling.value = true;
        await getLocalStream();
        // 向信令服务器发送发起请求
        let data = {
          toId: props.chat_id,
          message: "发起视频请求",
          msg_type: 4,
          file_name: "",
          ws_type: "视频",
        };
        props.socket.send(JSON.stringify(data));
      }

      // 接收方同意视频请求
      const acceptCall = () => {
        called.value = true;
        calling.value = true;
        let data = {
          toId: props.chat_id,
          message: "同意视频请求",
          msg_type: 4,
          file_name: "",
          ws_type: "视频",
        };
        props.socket.send(JSON.stringify(data));
      }

      // 挂断视频
      const hangUp = () => {
        called.value = false
        caller.value = false
        calling.value = false
        communicating.value = false
        peer.value = null
        localVideo.value.srcObject = null
        remoteVideo.value.srcObject = null
        localStream.value = undefined
      }

      // 发送offer信息
      const sendVideoCallOffer = async () => {
        // 创建RTCPeerConnection
        peer.value = new RTCPeerConnection()
        // 添加本地音视频流
        peer.value.addStream(localStream.value)

        // 用户A获取candidate信息并且通过信令服务器发送candidate给用户B
        peer.value.onicecandidate = (event) => {
          if (event.candidate) {
            // 通过信令服务器发送candidate信息给用户B
            console.log('通过信令服务器发送candidate信息给用户B');
            let candidate_data = {
              toId: props.chat_id,
              message: "发起candidate",
              msg_type: 4,
              file_name: JSON.stringify(event.candidate),
              ws_type: "视频",
            }
            props.socket.send(JSON.stringify(candidate_data));
          } else {
            console.log('candidate信息收集完毕');
          }
        }

        // 进行P2P通信
        // 监听onaddstream来获取对方的音视频流
        peer.value.onaddstream = (event) => {
          calling.value = false;
          communicating.value = true;
          remoteVideo.value.srcObject = event.stream
          remoteVideo.value.play()
        }

        // 生成offer
          offer.value = await peer.value.createOffer({
          offerToReceiveAudio: 1,
          offerToReceiveVideo: 1
        })
        // 通过信令服务器将offer发送给用户B
        let data = {
          toId: props.chat_id,
          message: "发起offer",
          msg_type: 4,
          file_name: JSON.stringify(offer.value),
          ws_type: "视频",
        };
        props.socket.send(JSON.stringify(data));
        // 设置本地描述的offer
        console.log('设置本地描述的offer');
        await peer.value.setLocalDescription(offer.value);
      }

      // 接收offer
      const receiveVideoCallOffer = async (offer) => {
        // 创建自己的RTCPeerConnection
        peer.value = new RTCPeerConnection()
        // 添加本地音视频流
        const stream = await getLocalStream()
        peer.value.addStream(stream)

        // 用户B获取candidate信息并且通过信令服务器发送candidate给用户A
        peer.value.onicecandidate = (event) => {
          if (event.candidate) {
            // 通过信令服务器发送candidate信息给用户A
            let candidate_data = {
              toId: props.chat_id,
              message: "发起candidate",
              msg_type: 4,
              file_name: JSON.stringify(event.candidate),
              ws_type: "视频",
            }
            props.socket.send(JSON.stringify(candidate_data));
          } else {
            console.log('candidate信息收集完毕');
          }
        }

        // 进行P2P通信
        // 监听onaddstream来获取对方的音视频流
        peer.value.onaddstream = (event) => {
          calling.value = false;
          communicating.value = true;
          remoteVideo.value.srcObject = event.stream
          remoteVideo.value.play()
        }

        // 设置远端描述信息
        await peer.value.setRemoteDescription(offer);
        answer.value = await peer.value.createAnswer()
        await peer.value.setLocalDescription(answer.value);
        // 发送answer给信令服务器
        let data = {
          toId: props.chat_id,
          message: "发起answer",
          msg_type: 4,
          file_name: JSON.stringify(answer.value),
          ws_type: "视频",
        };
        props.socket.send(JSON.stringify(data));
      }

      // 接收answer
      const receiveVideoCallAnswer = async (answer) => {
        peer.value.setRemoteDescription(answer);
      }

      // 接收candidate
      const receiveVideoCallCandidate = async (candidate) => {
        console.log('candidate', candidate);
        await peer.value.addIceCandidate(candidate);
      }


      return {
          called,
          caller,
          calling,
          communicating,
          callRemote,
          acceptCall,
          hangUp,
          localVideo,
          remoteVideo,
          sendVideoCallOffer,
          receiveVideoCallOffer,
          receiveVideoCallAnswer,
          receiveVideoCallCandidate,
      }
    }
}
</script>

<style scoped>

</style>