<template>
    <div class="container ckk1">
        <div class="card ckk2">
            <div class="card-body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name: 'ContentFiled',
}
</script>

<style scoped>
.ckk1
{
    margin-top: 20px;
    /* margin-bottom: 20px; */
}

.ckk2
{
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

</style>