import $ from 'jquery';
import { base_http_url } from '@/Utils/Base_Url';

export default {
    // 存储全局信息
    state: {
        id: "",
        uname: "",
        description: "",
        posts_num: "",
        friends_num: "",
        getPraises: "",
        photo: "",
        token: "",
        is_login: false,
        last_webpages: "",
    },
    getters: {
    },
    // 用于修改数据
    mutations: {
        updateUser(state, user) {
            state.id = user.id;
            state.uname = user.uname;
            state.photo = user.photo;
            state.description = user.description;
            state.posts_num = user.posts_num;
            state.friends_num = user.friends_num;
            state.getPraises = user.getPraises;
            state.is_login = true;
        },
        updateToken(state, token) {
            state.token = token;
        }
    },
    // 定义登录函数
    actions: {
        login(context, data) {
            $.ajax({
                url: base_http_url + "api/user/login/",
                type: "post",
                data: {
                   uname: data.uname,
                   password: data.password, 
                },
                success(resp) {
                    if(resp.err_msg == "success") {
                        localStorage.setItem("jwt_token", resp.token);
                        context.commit("updateToken", resp.token);
                        data.success();
                    } 
                    else {
                        data.error(resp);
                    }
                },
                error(resp) {
                    data.error(resp);
                }
            });
        },
        // 获取用户信息
        getInfo(context, data) {
            $.ajax({
                url: base_http_url + "api/user/info/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + context.state.token,
                },
                success(resp) {
                    if(resp.err_msg == "success") {
                        context.commit("updateUser", {
                            // 解构
                            ...resp,
                        });
                        context.state.is_login = true;
                        data.success();
                    } 
                    else {
                        data.error();
                    }
                },
                error() {
                    data.error();
                }
            });
        },
        // 退出登录
        logout(state) {
            state.id = '';
            state.uname = '';
            state.photo = '';
            state.is_login = false;
            state.token = '';
            localStorage.removeItem("jwt_token");
        },
        // 注册用户
        register(context, data) {
            $.ajax({
                url: base_http_url + "api/user/register/",
                type: "post",
                data: {
                    uname: data.uname,
                    password: data.password, 
                 },
                success(resp) {
                    if(resp.err_msg == "success") {
                        data.success();
                    } 
                    else {
                        data.error(resp);
                    }
                },
                error(resp) {
                    data.error(resp);
                }
            });
        }

    },
    modules: {
    }
}