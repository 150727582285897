<template>
  <div id="LoadingBlock" style="display: none;">
    <div style="position: fixed; width: 100%; height: 100%; color: rgb(81, 81, 81); font-size: 14px;">
      <div style="position: absolute; top: 40%; left: 47%; text-align: center;">
          <div class="spinner-border text-secondary" role="status"></div>
          <div> 加载中... </div>
      </div>
    </div>
  </div>
  <NavBar></NavBar>
  <div class="row">
      <div class="col-2"></div>
      <div class="col-8 kk1">
          <div style="font-size: 13px; margin: 10px 0; color: #4b6b78;">
              <span style="margin-left: 100px;"> 管理员kk，欢迎您~  </span>
              <img @click="getChatsByKeyWord" src="../assets/管理员.png" alt="管理员" style="float: right; width: 20px;">
              <input @input="getChatsByKeyWord" v-model="chat_search_msg" class="kks" type="text" placeholder="查找聊天记录~" >
          </div>
          <table class="table table-striped table-hover">
            <thead>
              <tr style="font-size: 14px;">
                <th> 发送id </th>
                <th> 接收id </th>
                <th> 发送时间 </th>
                <th> 类型 </th>
                <th> 内容 </th>
                <th> 删除 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="chat in chats" :key="chat.id">
                <td>
                  <div style="font-size: 13px; color: #5e5e5e; font-weight: bold;">{{ chat.from_id }}</div>
                </td>
                <td>
                  <div style="font-size: 13px; color: #5e5e5e; font-weight: bold;"> {{ chat.to_id }} </div>
                </td>
                <td>
                  <div style="font-size: 13px; color: #5e5e5e;"> {{ chat.send_time }} </div>
                </td>
                <td>
                  <div style="font-size: 12px; color: #5e5e5e;" v-if="chat.msg_type == 1"> 文字 </div>
                  <div style="font-size: 12px; color: #5e5e5e;" v-if="chat.msg_type == 2"> 图片 </div>
                  <div style="font-size: 12px; color: #5e5e5e;" v-if="chat.msg_type == 3"> 文件 </div>
                </td>
                <td>
                  <div style="font-size: 12px; color: #5e5e5e; word-break: break-all; max-width: 360px;" v-if="chat.msg_type == 1"> {{ chat.message }} </div>
                  <div style="font-size: 12px; color: #5e5e5e;" v-if="chat.msg_type == 2"> <img :src="chat.message" alt="" style="width: 40px; height: 40px;"> </div>
                  <div style="font-size: 12px; color: #5e5e5e;" v-if="chat.msg_type == 3"> <a :href="chat.message">{{ chat.file_name }}</a> </div>
                </td>
                <td>
                  <!-- Modal -->
                  <img src="../assets/管理员删除.png" alt="删除聊天记录" style="float: right; width: 20px;" data-bs-toggle="modal" :data-bs-target="'#del_chatinfo_confirm_' + chat.id">

                  <!-- 删除聊天记录确认 Modal -->
                  <div class="modal fade" :id="'del_chatinfo_confirm_' + chat.id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="staticBackdropLabel">删除聊天记录~</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" style="text-align: left;">
                            确认删除该聊天记录吗？
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger btn-sm" @click="remove_chat(chat)">删除</button>
                            <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">取消</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <div v-if="chats.length == 0 && chat_now_pages == 1" class="kk9"> 未找到任何相关聊天信息~ 
              <div style="margin-bottom: 20px;"></div>
            </div>
            <div v-if="chats.length == 0 && chat_now_pages > 1" class="kk9"> 到底啦~ </div>
            <div v-if="chats.length != 0 || chat_now_pages != 1" style="float: right; margin-right: 10px;">
              <img @click="chat_last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
              <span style="font-size: 12px;">&nbsp; {{ chat_now_pages }} &nbsp;</span>
              <img @click="chat_next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
              <div style="margin-bottom: 10px;"></div>
            </div>
          </div>

      </div>
  </div>

</template>

<script>
import NavBar from "../components/NavBar.vue";
// import { useRoute } from 'vue-router';
// import { onUpdated } from 'vue';
import { onMounted } from 'vue';
import { ref } from 'vue';
import store from '@/store/user';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';

import { base_http_url } from '@/Utils/Base_Url';



export default {
    name: 'KKRootChatBlock',
    components: {
      NavBar,
  
    },

    setup()
    {
        // Loading...
        const Loading = (type) => {
          let LoadingBlock = document.querySelector('#LoadingBlock');
          if(type == 1) {
            LoadingBlock.style.display = '';
          }
          else {
            LoadingBlock.style.display = 'none';
          }
        }

        onMounted(() => {
          getChatsByKeyWord();
        })

        let chats = ref([]);
        let chat_now_pages = ref(1);
        let chat_search_msg = ref('');

        // 获取聊天信息
        const getChatsByKeyWord = () => {
          Loading(1);
          $.ajax({
              url: base_http_url + "api/root/kk/chat/getinfo/",
              type: "post",
              data: {
                  pages: chat_now_pages.value,
                  message: chat_search_msg.value,
              },
              headers: {
                  Authorization: 'Bearer ' + store.state.token,
              },
              success(resp) {
                  chats.value = resp;
                  Loading(2);
              },
              error() {
              }
          });
        }

        // 翻页
        const chat_last_pages = () => {
            if(chat_now_pages.value <= 1) return;
            chat_now_pages.value = chat_now_pages.value - 1;
            getChatsByKeyWord();
        }

        const chat_next_pages = () => {
            if(chats.value.length == 0) return;
            chat_now_pages.value = chat_now_pages.value + 1;
            getChatsByKeyWord();
        }

        // 删除聊天记录
        const remove_chat = (chat) => {
            $.ajax({
                url: base_http_url + "api/root/kk/chat/remove/",
                type: "post",
                data: {
                    id: chat.id,
                },
                headers: {
                    Authorization: 'Bearer ' + store.state.token,
                },
                success(resp) {
                    if(resp.err_msg == 'success') {
                        // 关闭模态框
                        Modal.getInstance('#del_chatinfo_confirm_' + chat.id).hide();
                        // 刷新
                        getChatsByKeyWord();
                    }
                },
                error() {
                    
                }
            });
            }


        return {
            chats,
            chat_now_pages,
            chat_search_msg,
            getChatsByKeyWord,
            chat_last_pages,
            chat_next_pages,
            remove_chat,
            Loading,



        }
    },
  
  }
</script>

<style scoped>
img:hover {
  cursor: pointer;
}
.kks {
    float: right;
    height: 22px;
    width: 100px;
    padding-left: 5px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1.8vh;
    background-color: #D8D9D8;
}


.kk1
{
    background-color: #fcfcfc;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 3px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.kk9{
  text-align: center;
  color: rgb(254, 81, 81);
  font-size: 13px;
}

</style>