import { createRouter, createWebHistory } from 'vue-router'

import ChatBlock from '../views/ChatBlock';
import MySpaceBlock from '../views/MySpace';
import WorldBlock from '../views/WorldBlock';
import NotFound from '../views/NotFound';
import MyInfo from '../views/MyInfo';
import LoginView from '../views/LoginView';
import RegisterView from '../views/RegisterView'
import PostDetails from '../views/PostDetails';
import KKRootUserBlock from '../views/KKRootUser';
import KKRootPostBlock from '../views/KKRootPost'
import KKRootChatInfoBlock from '../views/KKRootChat'

import store from '../store/user';

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/world/',
    // 存储额外信息
    meta: {
      requestAuth: true,
      title: '世界 - kkChat',
    },
  },
  {
    path: '/login/',
    name: 'LoginIndex',
    component: LoginView,
    meta: {
      requestAuth: false,
      title: '登录kkChat',
    },
  },
  {
    path: '/register/',
    name: 'RegisterIndex',
    component: RegisterView,
    meta: {
      requestAuth: false,
      title: '欢迎注册',
    },
  },
  {
    path: '/chat/',
    name: 'ChatBlockIndex',
    component: ChatBlock,
    meta: {
      requestAuth: true,
      title: '聊天 - kkChat',
    },
  },
  {
    path: '/mySpace/',
    name: 'MySpaceBlockIndex',
    component: MySpaceBlock,
    meta: {
      requestAuth: true,
      title: '动态 - kkChat',
    },
  },
  {
    path: '/world/',
    name: 'WorldBlockIndex',
    component: WorldBlock,
    meta: {
      requestAuth: false,
      title: '世界 - kkChat',
    },
  },
  {
    path: '/info/',
    name: 'MyInfoIndex',
    component: MyInfo,
    meta: {
      requestAuth: true,
      title: '个人信息 - kkChat',
    },
  },
  {
    path: '/detail/:post_id',
    name: 'PostDetailBlock',
    component: PostDetails,
    meta: {
      requestAuth: false,
      title: '帖子 - kkChat',
    },
  },
  {
    path: '/root/user',
    name: 'KKRootUserBlock',
    component: KKRootUserBlock,
    meta: {
      requestAuth: true,
      title: '管理员 - kkChat',
    },
  },
  {
    path: '/root/post',
    name: 'KKRootPostBlock',
    component: KKRootPostBlock,
    meta: {
      requestAuth: true,
      title: '管理员 - kkChat',
    },
  },
  {
    path: '/root/chatinfo',
    name: 'KKRootChatInfoBlock',
    component: KKRootChatInfoBlock,
    meta: {
      requestAuth: true,
      title: '管理员 - kkChat',
    },
  },
  {
    path: '/404/',
    name: 'NotFound',
    component: NotFound,
    meta: {
      requestAuth: false,
      title: '404 - kkChat',
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404/',
    meta: {
      requestAuth: false,
      title: '404 - kkChat',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
});


// 设置前端页面访问权限
router.beforeEach((to,from,next) => {
  document.title = to.meta.title;
  if(to.meta.requestAuth == true && store.state.is_login == false) {
    store.state.last_webpages = to.name;
    next({name: 'LoginIndex'});
  }
  else {
    next();
  }
})



export default router
