<template>
    <router-view></router-view>
    <WebInfo></WebInfo>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

import WebInfo from './components/WebInfo.vue';


export default {
  components: {
    WebInfo,
  }

}

</script>


<style>
body {
    background-image: url(./assets/login.jpg);
    background-size: cover;
    overflow-x: hidden;
}
</style>
