<template>
  <div>
    <div style="position: absolute; top: 25%; left: 38%;">
      <img src="../assets/404.png" alt="404" style="width: 100px; margin-bottom: 15px;">
      <div style="display: inline-block; margin-left: 10px;">
        <span style="font-size: 18px;"> 页面不存在 </span>
        <div style="font-size: 14px; color: rgb(90, 90, 90);"> 抱歉！我们找不到您想访问的页面... </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
  components: {



  }

}
</script>

<style scoped></style>