<template>
  <div id="LoadingBlock" style="display: none;">
    <div style="position: fixed; width: 100%; height: 100%; color: rgb(81, 81, 81); font-size: 14px;">
      <div style="position: absolute; top: 40%; left: 47%; text-align: center;">
          <div class="spinner-border text-secondary" role="status"></div>
          <div> 加载中... </div>
      </div>
    </div>
  </div>
  <NavBar @worldsearch="WorldSearch"></NavBar>

  <div class="row">
    <div class="col-2"></div>
    <div  class="col-8 kkcol8">
      <div v-for="post in show_posts" :key="post.id" style="margin: 20px 0; border-bottom: 1px solid #d4d6dc;">
        <img :src="post.photo" alt="" style="float: left; height: 30px; margin-top: 10px;">
        <span class="kkname"> {{ post.uname }} </span>
        <img @click="add_friend_request(post)" src="../assets/添加好友.png" alt="添加好友" style="width: 20px; margin-top: -6px; margin-left: 5px;">
        <div></div>
        <span class="kkdescription"> {{ post.description }} </span>
        <div class="kk10" style="font-size: 18px; font-weight: bold; margin-top: 5px; margin-bottom: 0px; background-color: white;"> {{ post.title }} </div>
        <div class="kk10"> {{ post.content }} </div>
        <span class="kk5" >更新于 {{ post.modify_time }} , 阅读 {{ post.read_num }} , 获赞 {{ post.praises_num }} </span>
        <img src="../assets/评论.png" alt="详情" style="width: 20px; float: right; margin-right: 20px; margin-top: 10px;" @click="toPostDetail(post.id)">
        <img @click="give_praise(post)" v-if="!post.is_give_praise" src="../assets/点赞.png" alt="点赞" style="width: 20px; float: right; margin-right: 15px; margin-top: 10px;">
        <img @click="cancel_praise(post)" v-else src="../assets/点赞_块.png" alt="取消点赞" style="width: 20px; float: right; margin-right: 15px; margin-top: 10px;">
        <div style="margin-bottom: 50px;"></div>
      </div>
      <div v-if="show_posts.length == 0 && now_pages == 1">
          <div class="kk3"> 暂无帖子，快去发帖吧~ </div>
      </div>
      <div v-if="show_posts.length == 0 && now_pages > 1">
          <div class="kk3"> 到底啦~ </div>
      </div>
      <div v-if="show_posts.length != 0 || now_pages != 1" style="float: right; margin-right: 10px;">
        <img @click="last_pages" src="../assets/左翻页.png" alt="上一页" style="width: 10px;">
        <span style="font-size: 12px;">&nbsp; {{ now_pages }} &nbsp;</span>
        <img @click="next_pages" src="../assets/右翻页.png" alt="下一页" style="width: 10px;">
        <div style="margin-bottom: 10px;"></div>
      </div>
    </div>
  </div>

  <!-- Button trigger modal -->
  <button id="request_errmsg_trigger" style="display: none;" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addFriendRequsetModal">
    Launch demo modal
  </button>

  <!-- 添加好友 Modal -->
  <div class="modal fade" id="addFriendRequsetModal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">消息</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div id="addrequest_errmsg"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal">确定</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import ContentFiled from '../components/ContentFiled.vue'
// import { useRoute } from 'vue-router';
import NavBar from "../components/NavBar.vue";
import { useStore } from 'vuex';
import router from '@/router/index';
import { onMounted } from 'vue';
import { ref } from 'vue';
import $ from 'jquery';
import store from '@/store/user';

import { base_http_url } from '@/Utils/Base_Url';


export default {
  name: 'WorldBlock',
  components: {
    // ContentFiled,
    NavBar,

  },
  setup() {
    // Loading...
    const Loading = (type) => {
      let LoadingBlock = document.querySelector('#LoadingBlock');
      if(type == 1) {
        LoadingBlock.style.display = '';
      }
      else {
        LoadingBlock.style.display = 'none';
      }
    }

    const info_store = useStore();
    const jwt_token = localStorage.getItem("jwt_token");
    let now_pages = ref(1);
    let show_posts = ref([]);
    let err_msg = ref('');
    let is_login = ref(false);

    onMounted(() => {
      // 进行登录
      if(jwt_token) {
        info_store.commit('updateToken',jwt_token);
        info_store.dispatch("getInfo", {
          success() {
            is_login.value = true;
            refresh_showPosts_Login();
          },
          error() {
            refresh_showPosts_unLogin();
          }
        });
      }
      else refresh_showPosts_unLogin();
    });

    // 获取帖子
    const refresh_showPosts_unLogin = () => {
      Loading(1);
      $.ajax({
          url: base_http_url + "api/user/post/showposts/",
          type: "get",
          data: {
            pages: now_pages.value,
          },
          success(resp) {
            show_posts.value = resp;
            Loading(2);       
          },
      });
    };

    const refresh_showPosts_Login = () => {
      Loading(1);
      $.ajax({
        url: base_http_url + "api/user/post/loginposts/",
        type: "get",
        data: {
          pages: now_pages.value,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success(resp) {
          show_posts.value = resp;
          Loading(2);
        },
      });
    };

    // // 进行登录
    // if(jwt_token) {
    //   info_store.commit('updateToken',jwt_token);
    //   info_store.dispatch("getInfo", {
    //     success() {
    //       is_login.value = true;
    //       refresh_showPosts_Login();
    //     },
    //     error() {
    //       refresh_showPosts_unLogin();
    //     }
    //   });
    // }
    // else refresh_showPosts_unLogin();


    // 翻页
    const last_pages = () => {
      if(now_pages.value <= 1) return;
      now_pages.value = now_pages.value - 1;
      if(store.state.is_login) {
        refresh_showPosts_Login();
      }
      else refresh_showPosts_unLogin();
    }

    const next_pages = () => {
      if(show_posts.value.length == 0) return;
      now_pages.value = now_pages.value + 1;
      if(store.state.is_login) {
        refresh_showPosts_Login();
      }
      else refresh_showPosts_unLogin();
    }



    // 点赞
    const give_praise = (post) => {
      if(!info_store.state.user.is_login) router.push({name: 'LoginIndex'});
      $.ajax({
        url: base_http_url + "api/user/post/givePraise/",
        type: "post",
        data: {
          post_id: post.id,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success() {
          post.is_give_praise = true;
          post.praises_num = post.praises_num + 1;
        },
        error() {
        }
      });
    }

    const cancel_praise = (post) => {
      if(!info_store.state.user.is_login) router.push({name: 'LoginIndex'});
      $.ajax({
        url: base_http_url + "api/user/post/cancelPraise/",
        type: "post",
        data: {
          post_id: post.id,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success() {
          post.is_give_praise = false;
          post.praises_num = post.praises_num - 1;
        },
        error() {
        }
      });
    }

    // 申请添加好友
    const add_friend_request = (post) => {
      if(!info_store.state.user.is_login) router.push({name: 'LoginIndex'});
      let request_errmsg_trigger = document.querySelector('#request_errmsg_trigger');
      let addrequest_errmsg = document.querySelector('#addrequest_errmsg');
      $.ajax({
        url: base_http_url + "api/user/friend/addrequest/",
        type: "post",
        data: {
          id: post.writer_id,
        },
        headers: {
          Authorization: 'Bearer ' + store.state.token,
        },
        success(resp) {
          if(resp.err_msg == 'success') addrequest_errmsg.innerText = '消息已发送，等待对方同意~';
          else addrequest_errmsg.innerText = resp.err_msg;
          request_errmsg_trigger.click();
        },
        error(resp) {
          addrequest_errmsg.innerText = resp.err_msg;
          request_errmsg_trigger.click();
        }
      });
    }

    // 在世界中搜索
    const WorldSearch = (message) => {
        now_pages.value = 1;
        Loading(1);
        if(!is_login.value) {
          $.ajax({
            url: base_http_url + "api/user/post/search/",
            type: "get",
            data: {
              pages: now_pages.value,
              message: message,
            },
            success(resp) {
              show_posts.value = resp;
              Loading(2);
            },
          });
        }
        else {
          $.ajax({
            url: base_http_url + "api/user/post/loginsearch/",
            type: "get",
            data: {
              pages: now_pages.value,
              message: message,
            },
            headers: {
              Authorization: 'Bearer ' + store.state.token,
            },
            success(resp) {
              show_posts.value = resp;
              Loading(2);
            },
          });
        }

    }

    // 跳转帖子详情页
    const toPostDetail = (post_id) => {
      window.open('/detail/' + post_id, '_blank');
      // router.push({
      //   name: 'PostDetailBlock',
      //   params: {
      //     post_id: post_id,
      //   },
      // });
    }


    return {
        info_store,
        show_posts,
        now_pages,
        last_pages,
        next_pages,
        err_msg,
        is_login,
        give_praise,
        cancel_praise,
        add_friend_request,
        WorldSearch,
        toPostDetail,
        Loading,
        // LoadingBlock,

    }
  }


  }
</script>

<style scoped>
img:hover {
  cursor: pointer;
}

.kkcol8 {
  background-color: #ffffff;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.kkname {
  margin-left: 8px;
  font-size: 14px;
  font-weight: bold;
}

.kkdescription {
  margin-top: -5px;
  margin-left: 8px;
  font-size: 10px;
  color: #949494;
}

.kk3 {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  color: rgb(254, 81, 81);
}

.kk5{
  float: left;
  margin-top: 5px;
  font-size: 10px;
  text-align: left;
  color: rgb(168, 168, 168);
}

.kk10
{
  /* 正常换行 */
  white-space: pre;
  padding: 5px 5px;
  margin-top: 10px;
  max-width: 100%;
  text-align: left;
  max-height: 100px;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: rgb(248, 248, 248);
}


</style>